/*
 * This file is generated by ~/modules/gql.ts
 * Do not edit this file manually
 */

import type {
  AccountFileQuery,
  AccountFileQueryVariables,
  CurrentCustodyQuery,
  CurrentCustodyQueryVariables,
  FullNameQuery,
  FullNameQueryVariables,
  UserQuery,
  UserQueryVariables,
  AddressQuery,
  AddressQueryVariables,
  AddressFromPostalCodeQuery,
  AddressFromPostalCodeQueryVariables,
  BrazilianStatesQuery,
  BrazilianStatesQueryVariables,
  CitiesByStateQuery,
  CitiesByStateQueryVariables,
  UpdateAddressMutation,
  UpdateAddressMutationVariables,
  BankInfoQuery,
  BankInfoQueryVariables,
  UpdateUserBankInfoMutation,
  UpdateUserBankInfoMutationVariables,
  ChangeEmailMutation,
  ChangeEmailMutationVariables,
  ChangeEmailConfirmMutation,
  ChangeEmailConfirmMutationVariables,
  PatrimonialDataQuery,
  PatrimonialDataQueryVariables,
  UpdatePatrimonialDataMutation,
  UpdatePatrimonialDataMutationVariables,
  ChangePhoneMutation,
  ChangePhoneMutationVariables,
  ChangePhoneConfirmMutation,
  ChangePhoneConfirmMutationVariables,
  AddPixInfoMutation,
  AddPixInfoMutationVariables,
  PixInfoQuery,
  PixInfoQueryVariables,
  RemovePixInfoMutation,
  RemovePixInfoMutationVariables,
  WalletOperationQuery,
  WalletOperationQueryVariables,
} from "~/graphql/generated/graphql";

export const useGqlAccountFile = () => {
  const { $gql } = useNuxtApp();

  return (variables?: AccountFileQueryVariables) => {
    return $gql<AccountFileQuery>(
      `query AccountFile{accountFile{documentType fileId fileName createdAt fileUrl accountId fileDescription isVisible title uploadedBy}}`,
      variables,
    );
  };
};

export const useGqlCurrentCustody = () => {
  const { $gql } = useNuxtApp();

  return (variables?: CurrentCustodyQueryVariables) => {
    return $gql<CurrentCustodyQuery>(
      `query CurrentCustody{accountCurrentCustody{amount}}`,
      variables,
    );
  };
};

export const useGqlFullName = () => {
  const { $gql } = useNuxtApp();

  return (variables?: FullNameQueryVariables) => {
    return $gql<FullNameQuery>(`query FullName{user{fullName}}`, variables);
  };
};

export const useGqlUser = () => {
  const { $gql } = useNuxtApp();

  return (variables?: UserQueryVariables) => {
    return $gql<UserQuery>(
      `query User{user{registerCodeStatus birthdate category fullName nickName email phone gender maritalStatus nationality nid profession partner kycAML{status}patrimonialData{financialInvestments others monthlyIncome realOrAlternative personalty chattel}}}`,
      variables,
    );
  };
};

export const useGqlAddress = () => {
  const { $gql } = useNuxtApp();

  return (variables?: AddressQueryVariables) => {
    return $gql<AddressQuery>(
      `query Address{user{address{city complement country nationality neighborhood number postalCode state street}}}`,
      variables,
    );
  };
};

export const useGqlAddressFromPostalCode = () => {
  const { $gql } = useNuxtApp();

  return (variables?: AddressFromPostalCodeQueryVariables) => {
    return $gql<AddressFromPostalCodeQuery>(
      `query AddressFromPostalCode($zipcode: String!){zipcode(zipcode: $zipcode){city neighborhood service state street zipcode}}`,
      variables,
    );
  };
};

export const useGqlBrazilianStates = () => {
  const { $gql } = useNuxtApp();

  return (variables?: BrazilianStatesQueryVariables) => {
    return $gql<BrazilianStatesQuery>(
      `query BrazilianStates{states{code name}}`,
      variables,
    );
  };
};

export const useGqlCitiesByState = () => {
  const { $gql } = useNuxtApp();

  return (variables?: CitiesByStateQueryVariables) => {
    return $gql<CitiesByStateQuery>(
      `query CitiesByState($state: String!){regions(code: $state){name}}`,
      variables,
    );
  };
};

export const useGqlUpdateAddress = () => {
  const { $gql } = useNuxtApp();

  return (variables?: UpdateAddressMutationVariables) => {
    return $gql<UpdateAddressMutation>(
      `mutation UpdateAddress($address: UpdateAddressDTO!){updateAddress(updateAddressDTO: $address){__typename}}`,
      variables,
    );
  };
};

export const useGqlBankInfo = () => {
  const { $gql } = useNuxtApp();

  return (variables?: BankInfoQueryVariables) => {
    return $gql<BankInfoQuery>(
      `query BankInfo{user{bankInfo{agency bank bankCode cc default type}}}`,
      variables,
    );
  };
};

export const useGqlUpdateUserBankInfo = () => {
  const { $gql } = useNuxtApp();

  return (variables?: UpdateUserBankInfoMutationVariables) => {
    return $gql<UpdateUserBankInfoMutation>(
      `mutation UpdateUserBankInfo($bankId: String! $bankInfo: UpdateUserBankInfoDTO!){updateUserBankInfo(bankId: $bankId, updateUserBankInfoDTO: $bankInfo){__typename}}`,
      variables,
    );
  };
};

export const useGqlChangeEmail = () => {
  const { $gql } = useNuxtApp();

  return (variables?: ChangeEmailMutationVariables) => {
    return $gql<ChangeEmailMutation>(
      `mutation ChangeEmail($email: String!){requestEmailChange(newEmail: $email){__typename}}`,
      variables,
    );
  };
};

export const useGqlChangeEmailConfirm = () => {
  const { $gql } = useNuxtApp();

  return (variables?: ChangeEmailConfirmMutationVariables) => {
    return $gql<ChangeEmailConfirmMutation>(
      `mutation ChangeEmailConfirm($code: String!){confirmEmailChange(code: $code){__typename}}`,
      variables,
    );
  };
};

export const useGqlPatrimonialData = () => {
  const { $gql } = useNuxtApp();

  return (variables?: PatrimonialDataQueryVariables) => {
    return $gql<PatrimonialDataQuery>(
      `query PatrimonialData{user{patrimonialData{chattel financialInvestments monthlyIncome others personalty realOrAlternative}}}`,
      variables,
    );
  };
};

export const useGqlUpdatePatrimonialData = () => {
  const { $gql } = useNuxtApp();

  return (variables?: UpdatePatrimonialDataMutationVariables) => {
    return $gql<UpdatePatrimonialDataMutation>(
      `mutation UpdatePatrimonialData($input: UpdateUserBasicDetailsDTO!){updateUserBasicDetails(updateUserBasicDetailsDTO: $input){patrimonialData{chattel financialInvestments monthlyIncome others personalty realOrAlternative}}}`,
      variables,
    );
  };
};

export const useGqlChangePhone = () => {
  const { $gql } = useNuxtApp();

  return (variables?: ChangePhoneMutationVariables) => {
    return $gql<ChangePhoneMutation>(
      `mutation ChangePhone($phone: String!){requestPhoneChange(newPhone: $phone){__typename}}`,
      variables,
    );
  };
};

export const useGqlChangePhoneConfirm = () => {
  const { $gql } = useNuxtApp();

  return (variables?: ChangePhoneConfirmMutationVariables) => {
    return $gql<ChangePhoneConfirmMutation>(
      `mutation ChangePhoneConfirm($code: String!){confirmPhoneChange(code: $code){__typename}}`,
      variables,
    );
  };
};

export const useGqlAddPixInfo = () => {
  const { $gql } = useNuxtApp();

  return (variables?: AddPixInfoMutationVariables) => {
    return $gql<AddPixInfoMutation>(
      `mutation AddPixInfo{addPixInfo{__typename}}`,
      variables,
    );
  };
};

export const useGqlPixInfo = () => {
  const { $gql } = useNuxtApp();

  return (variables?: PixInfoQueryVariables) => {
    return $gql<PixInfoQuery>(
      `query PixInfo{user{pixInfo{value type}}}`,
      variables,
    );
  };
};

export const useGqlRemovePixInfo = () => {
  const { $gql } = useNuxtApp();

  return (variables?: RemovePixInfoMutationVariables) => {
    return $gql<RemovePixInfoMutation>(
      `mutation RemovePixInfo{removePixInfo{__typename}}`,
      variables,
    );
  };
};

export const useGqlWalletOperation = () => {
  const { $gql } = useNuxtApp();

  return (variables?: WalletOperationQueryVariables) => {
    return $gql<WalletOperationQuery>(
      `query WalletOperation($operationId: String!){walletOperation(operationId: $operationId){accountOperationFiles{date description fileUrl name}assetName assetSymbol baseAssetSymbol currentCustodyBalance currentMultipleFactor currentPu currentReturnedPercent dateInvested endDateOperation: estimatedEnd estimatedIRR estimatedCompositeIRR income mediaInfo{legalDocuments{title file}otherDocuments{title file}}multipleFactor multipleFactorPrime operationId operationName originalScenarioInfo{list{base{IRR IRRTBond durationType durationUnit multipleFactor}optmistic{IRR IRRTBond durationType durationUnit multipleFactor}pessimistic{IRR IRRTBond durationType durationUnit multipleFactor}reviewed{IRR IRRTBond durationType durationUnit maximumExposure multipleFactor}}}realPu scenarioEstimate{endDate iRR multipleFactor type updatedDate}startDate tokenPrice tokens}}`,
      variables,
    );
  };
};
